<template>
  <div class="shopinfo">
    <van-nav-bar
      title="会员资料"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-form @submit="onSubmit" ref="info">
        <van-field
          v-model="info.avatar"
          name="avatar"
          label="头像"
          placeholder="头像"
          is-link
          center
          :rules="[{ required: true, message: '请上传头像' }]"
        >
          <template #input>
            <van-uploader
              v-model="uploader"
              :max-count="1"
              :after-read="logoupload"
              :before-delete="logodelete"
            />
          </template>
        </van-field>
        <van-field
          v-model="info.ninckname"
          name="ninckname"
          label="昵称"
          placeholder="昵称"
          is-link
          :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnload" :native-type="btnload?'button':'submit'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Teaminfo",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      uploader: [], // 上传文件
      btnload: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "team/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            if (that.info.avatar) {
              that.uploader.push({
                url: that.info.avatar,
                isImage: true
              });
              console.log(that.uploader);
            }else {
              that.uploader = [];
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    // logo上传
    logoupload(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      console.log(file, detail);
      let that = null;
      that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/avatar/');

      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          file.status = 'done';
          file.message = '上传完成';
          that.info.avatar = response.data.img_url
        }
      }).catch( error =>{
        console.log(error);
        file.status = 'failed';
        file.message = '上传失败';
      })
    },
    // 删除头像
    logodelete () {
      this.uploader = [];
      this.info.avatar = "";
    },
    // 保存按钮
    onSubmit() {
      console.log("submit");
      let that = null;
      that = this;
      if (!that.info.avatar) {
        that.$toast("请上传头像");
        return false;
      }
      that.btnload = true;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/user/set_info",
          that.$qs.stringify({
            avatar: that.info.avatar,
            nickname: that.info.nickname
          })
        )
        .then(res => {
          console.log(res);
          that.btnload = false;
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "保存成功");
            setTimeout(() => {
              that.onClickLeft();
            }, 1500);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "保存失败");
          }
        })
        .catch(err => {
          console.log(err);
          that.btnload = false;
        })
    }
  },
}
</script>
<style lang="less">
.van-uploader__preview-delete{
  width: 20px;
  height: 20px;
  border-radius: 0 0 0 16px;
}
.van-uploader__preview-delete-icon{
  font-size: 20px;
}
.shopinfo {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .main {
    overflow: hidden;
    width: 100%;
    .van-cell {
      line-height: 30px;
      .van-cell__title {
        width: 75px;
      }
      .van-cell__value {
        flex: 1;
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          .van-uploader__upload {
            margin: 0;
            border-radius: 50%;
            overflow: hidden;
          }
          .van-uploader__preview {
            margin: 0;
            .van-uploader__preview-image {
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
      .van-cell__right-icon {
        line-height: 30px;
      }
      .van-field__error-message {
        text-align: right;
      }
    }
  }
}
</style>